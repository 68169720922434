import { Controller } from "@stimulus/core";
import axios from "axios";
import Turbolinks from "turbolinks";
import swal from "sweetalert";

import { disableButton, preserveScroll } from "./utils";

export default class extends Controller {
  static values = {
    reviewId: String,
    groupId: String,
  };

  /**
   * Verify the solution group. Moderator only.
   * @param {MouseEvent} event
   * @returns {Promise<void>}
   */
  async verify(event) {
    await this.doAction(event, "verify", async () => {
      await successMessage("Try refreshing the page in a bit.");
    });
  }

  /**
   * Invalidate the solution group without verifying. Moderator only.
   * @param {MouseEvent} event
   * @returns {Promise<void>}
   */
  async invalidate(event) {
    await this.doAction(event, "invalidate");
  }

  /**
   * Flag the solution group as cheat. Moderator only.
   * @param {MouseEvent} event
   * @returns {Promise<void>}
   */
  async flag(event) {
    await this.doAction(event, "cheat_detected");
  }

  /**
   * Unflag the solution group as cheat. Moderator only.
   * @param {MouseEvent} event
   * @returns {Promise<void>}
   */
  async unflag(event) {
    await this.doAction(event, "cheat_corrected");
  }

  /**
   * Hide the solution group from the list. Moderator only.
   * @param {MouseEvent} event
   * @returns {Promise<void>}
   */
  async hide(event) {
    await this.doAction(event, "hide");
  }

  /**
   * Unhide the solution group from the list. Moderator only.
   * @param {MouseEvent} event
   * @returns {Promise<void>}
   */
  async unhide(event) {
    await this.doAction(event, "unhide");
  }

  /**
   * @param {MouseEvent} event
   * @param {String} action
   * @returns {Promise<void>}
   */
  async doAction(event, action, onSuccess = onSuccessReload) {
    disableButton(event.currentTarget);
    event.stopPropagation();

    try {
      const response = await axios.post(`${this.solutionGroupUrl}/${action}`);
      const json = response.data;
      if (json.success) return await onSuccess();
    } catch (err) {
      console.error(err.message);
    }
    await failureMessage();
  }

  get reviewUrl() {
    return `/kata/reviews/${this.reviewIdValue}`;
  }

  get solutionGroupUrl() {
    return `${this.reviewUrl}/groups/${this.groupIdValue}`;
  }
}

const onSuccessReload = async () => {
  await successMessage();
  preserveScroll();
  Turbolinks.visit();
};

const successMessage = async (text = "") => {
  await swal({
    // NOTE swal's success has animation that looks terrible on dark mode and not worth trying to make it work
    icon: "info",
    text: text || "Action Successful",
  });
};

const failureMessage = async (text = "") => {
  await swal({
    icon: "error",
    text:
      text ||
      "Something went wrong. Check the console for any error messages, and let the admin know if the problem persists.",
  });
};
